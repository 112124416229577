import React from "react";
import { FaCoffee, FaGithub, FaPortrait } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-gray-800 text-white p-6 mt-auto">
      <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Topics Section */}
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold mb-2">{t("Topics")}</h2>
          <Link to="/dictionary" className="hover:underline mb-1">
            {t("Dictionary")}
          </Link>
          <Link to="/create-record" className="hover:underline mb-1">
            {t("Dictionary.CreateButton")}
          </Link>
          <Link to="/request" className="hover:underline mb-1">
            {t("Dictionary.RequestButton")}
          </Link>
          <Link to="/blog" className="hover:underline mb-1">
            {t("Blog")}
          </Link>
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold mb-2">{t("Quick Links")}</h2>
          <Link to="/" className="hover:underline mb-1">
            {t("Home")}
          </Link>
          <Link to="/about-us" className="hover:underline mb-1">
            {t("AboutUs.Title")}
          </Link>
          <Link to="/faq" className="hover:underline">
            {t("FAQ")}
          </Link>
        </div>

        {/* Legal Links */}
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold mb-2">{t("Legal")}</h2>
          <Link to="/privacy-policy" className="hover:underline mb-1">
            {t("Privacy Policy")}
          </Link>
          <Link to="/terms-and-conditions" className="hover:underline mb-1">
            {t("Terms & Conditions")}
          </Link>
          <Link to="/imprint" className="hover:underline">
            {t("Imprint")}
          </Link>
        </div>

        {/* More Links */}
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold mb-2">{t("More")}</h2>
          <a
            href="https://nicowickersheim.dev"
            target="_blank"
            rel="noreferrer"
            className="flex items-center hover:underline mb-1"
          >
            <FaPortrait className="mr-1" /> Portfolio
          </a>
          <a
            href="https://github.com/wickenico"
            target="_blank"
            rel="noreferrer"
            className="flex items-center hover:underline mb-1"
          >
            <FaGithub className="mr-1" /> GitHub
          </a>
          <a
            href="https://ko-fi.com/N4N6EEQB"
            target="_blank"
            rel="noreferrer"
            className="flex items-center hover:underline mb-1"
          >
            <FaCoffee className="mr-1" /> {t("Buy me a coffee")}
          </a>
        </div>
      </div>

      <div className="text-center mt-6">
        <p className="text-xs">
          2025 &copy; madagassisch.de - Made with ❤️ in Freiburg
        </p>
      </div>
    </footer>
  );
}
