import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-toastify";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import Spinner from "../components/Spinner";
import DictionaryTable from "../components/DictionaryTable";
import { Link } from "react-router-dom";
import { FcPlus, FcDecision } from "react-icons/fc";
import { FaSearch, FaTimes, FaPaw } from "react-icons/fa";
import {
  FaArrowDownAZ,
  FaArrowDownZA,
  FaBurger,
  FaPlane,
  FaBook,
  FaLaptopCode,
  FaMasksTheater,
  FaFilterCircleXmark,
  FaHashtag,
  FaPalette,
  FaChildren,
  FaTree,
  FaPerson,
  FaTableTennisPaddleBall,
  FaCircleChevronRight,
  FaCircleChevronLeft,
  FaShirt,
  FaHouse,
  FaClock,
  FaCalendarDays,
  FaFilePdf,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { TAGS } from "../tags";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DictionaryPDF from "../components/DictionaryPDF";

export default function Dictionary() {
  const [rawRecords, setRawRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortOption, setSortOption] = useState("german_asc");
  const [showAllTags, setShowAllTags] = useState(false);
  const [visibleTags, setVisibleTags] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const recordRef = collection(db, "records");
    const q = query(recordRef, where("status", "==", "Accepted"));
    const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const recordsData = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            recordsData.push({
              id: doc.id,
              german: data.germanWord,
              translation: data.translation,
              phoneticSpelling: data.phoneticSpelling,
              tags: data.tags || [],
              article: data.article,
            });
          });
          setRawRecords(recordsData);
          setLoading(false);
        },
        (error) => {
          toast.error(t("Toast.FetchListing.Error"));
        }
    );
    return () => unsubscribe();
  }, [t]);

  useEffect(() => {
    let sortedRecords = [...rawRecords];

    if (sortOption === "german_asc") {
      sortedRecords.sort((a, b) =>
          a.german.localeCompare(b.german, undefined, { sensitivity: "base" })
      );
    } else if (sortOption === "german_desc") {
      sortedRecords.sort((a, b) =>
          b.german.localeCompare(a.german, undefined, { sensitivity: "base" })
      );
    } else if (sortOption === "malagasy_asc") {
      sortedRecords.sort((a, b) =>
          a.translation.localeCompare(b.translation, undefined, {
            sensitivity: "base",
          })
      );
    } else if (sortOption === "malagasy_desc") {
      sortedRecords.sort((a, b) =>
          b.translation.localeCompare(a.translation, undefined, {
            sensitivity: "base",
          })
      );
    } else if (sortOption === "numeric_asc") {
      sortedRecords.sort((a, b) => parseFloat(a.german) - parseFloat(b.german));
    } else if (sortOption === "numeric_desc") {
      sortedRecords.sort((a, b) => parseFloat(b.german) - parseFloat(a.german));
    }

    const isNumeric = (value) =>
        !isNaN(parseFloat(value)) && isFinite(value);
    sortedRecords.sort((a, b) => {
      const numA = isNumeric(a.german) ? parseFloat(a.german) : a.german;
      const numB = isNumeric(b.german) ? parseFloat(b.german) : b.german;
      return numA - numB;
    });

    setRecords(sortedRecords);
    setFilteredRecords(sortedRecords);
  }, [rawRecords, sortOption]);

  useEffect(() => {
    setVisibleTags(Object.values(TAGS).slice(0, 3));
  }, []);

  const toggleShowAllTags = () => {
    setShowAllTags(!showAllTags);
    if (!showAllTags) {
      setVisibleTags(Object.values(TAGS));
    } else {
      setVisibleTags(Object.values(TAGS).slice(0, 3));
    }
  };

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    toast.success(t("Toast.CopyCell.Success"));
  };

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
    const filtered = records.filter((record) => {
      const searchTermLower = e.target.value.toLowerCase();
      const germanMatch = record.german.toLowerCase().includes(searchTermLower);
      const translationMatch = record.translation
          .toLowerCase()
          .includes(searchTermLower);
      return germanMatch || translationMatch;
    });
    setFilteredRecords(filtered);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSortOptionChange = (option) => {
    setSortOption(option);
    setIsDropdownOpen(false);
  };

  const filterRecordsByTag = (tag) => {
    const isSameTag = selectedTag === tag;
    setSelectedTag(isSameTag ? null : tag);
    const filtered = records.filter((record) => {
      const match =
          record.tags &&
          record.tags.some(
              (t) => t.toLowerCase() === tag.toLowerCase()
          );
      return isSameTag ? true : match;
    });
    setFilteredRecords(filtered);
  };

  const resetFilters = () => {
    setSelectedTag(null);
    setSortOption("german_asc");
    setFilteredRecords(records);
  };

  const getTagIcon = (tag) => {
    switch (tag) {
      case `${TAGS.FOOD}`:
        return <FaBurger className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.TRAVEL}`:
        return <FaPlane className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.EDUCATION}`:
        return <FaBook className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.TECHNOLOGY}`:
        return <FaLaptopCode className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.CULTURE}`:
        return <FaMasksTheater className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.NUMBER}`:
        return <FaHashtag className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.ANIMALS}`:
        return <FaPaw className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.COLORS}`:
        return <FaPalette className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.FAMILY}`:
        return <FaChildren className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.NATURE}`:
        return <FaTree className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.BODY}`:
        return <FaPerson className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.ACTIVITY}`:
        return (
            <FaTableTennisPaddleBall
                className="mr-2 h-5 w-5"
                aria-hidden="true"
            />
        );
      case `${TAGS.CLOTHING}`:
        return <FaShirt className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.HOUSE}`:
        return <FaHouse className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.TIME}`:
        return <FaClock className="mr-2 h-5 w-5" aria-hidden="true" />;
      case `${TAGS.CALENDAR}`:
        return <FaCalendarDays className="mr-2 h-5 w-5" aria-hidden="true" />;
      default:
        return null;
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFilteredRecords(records);
  };

  return (
      <div className="max-w-6xl mx-auto px-3 flex flex-col items-center justify-center">
        <h1 className="text-3xl text-center mt-6 font-bold mb-6">{t("Dictionary")}</h1>
        <button
            type="submit"
            className="lg:w-[40%] bg-blue-600 text-white uppercase px-7 py-3 text-sm font-medium rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800 mb-6"
        >
          <Link to="/create-record" className="flex justify-center items-center">
            <FcPlus className="mr-2 text-3xl bg-white rounded-full p-1 border-2" />
            {t("Dictionary.CreateButton")}
          </Link>
        </button>
        <button
            type="submit"
            className="lg:w-[40%] bg-blue-600 text-white uppercase px-7 py-3 text-sm font-medium rounded shadow-md hover:bg-blue-700 transition duration-150 ease-in-out hover:shadow-lg active:bg-blue-800 mb-6"
        >
          <Link to="/request" className="flex justify-center items-center">
            <FcDecision className="mr-2 text-3xl bg-white rounded-full p-1 border-2" />
            {t("Dictionary.RequestButton")}
          </Link>
        </button>
        <div className="mb-4 relative">
          <div className="flex items-center">
          <span className="absolute left-4 text-gray-500">
            <FaSearch
                className={`transition-colors ${searchTerm ? "text-blue-600" : ""}`}
            />
          </span>
            <input
                type="text"
                placeholder={t("Dictionary.SearchPlaceholder")}
                value={searchTerm}
                onChange={handleSearchInputChange}
                className="w-full px-10 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600"
            />
            {searchTerm && (
                <span
                    onClick={handleClearSearch}
                    className="absolute right-4 cursor-pointer text-red-500 hover:text-red-600 transition duration-150 ease-in-out"
                >
              <FaTimes />
            </span>
            )}
          </div>
        </div>
        <div className="mb-4 relative flex items-center justify-between">
          <Menu as="div" className="relative inline-block text-left mr-4">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-blue-700">
                {t("Dictionary.SortBy")}{" "}
                <ChevronDownIcon
                    className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                        <button
                            onClick={() => handleSortOptionChange("german_asc")}
                            className={`${
                                active ? "bg-blue-600 text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FaArrowDownAZ
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                          />
                          {t("German")}
                        </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                        <button
                            onClick={() => handleSortOptionChange("german_desc")}
                            className={`${
                                active ? "bg-blue-600 text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FaArrowDownZA
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                          />
                          {t("German")}
                        </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                        <button
                            onClick={() => handleSortOptionChange("malagasy_asc")}
                            className={`${
                                active ? "bg-blue-600 text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FaArrowDownAZ
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                          />
                          {t("Malagasy")}
                        </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                        <button
                            onClick={() => handleSortOptionChange("malagasy_desc")}
                            className={`${
                                active ? "bg-blue-600 text-white" : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <FaArrowDownZA
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                          />
                          {t("Malagasy")}
                        </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu as="div" className="relative inline-block text-left mr-4">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-blue-700">
                {t("Options")}{" "}
                <ChevronDownIcon
                    className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                      <button
                          onClick={() => resetFilters()}
                          className={`${
                              active ? "bg-red-600 text-white" : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        <FaFilterCircleXmark
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                        />
                        {t("Reset")}
                      </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
          <PDFDownloadLink
              document={<DictionaryPDF records={records} />}
              fileName={t("Dictionary.PDFFileName")}
          >
            {({ blob, url, loading, error }) => (
                <button
                    type="button"
                    className="inline-flex justify-center items-center w-full rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-blue-700"
                >
                  <FaFilePdf className="mr-2 h-5 w-5" />
                  {loading
                      ? t("Dictionary.PDFDownloadLoading")
                      : t("Dictionary.PDFDownloadButton")}
                </button>
            )}
          </PDFDownloadLink>
        </div>
        <div className="mb-4">
          <div className="flex flex-wrap gap-2 justify-center">
            {visibleTags.map((tag) => (
                <button
                    key={tag}
                    onClick={() => filterRecordsByTag(tag)}
                    className={`${
                        selectedTag === tag
                            ? "bg-blue-700 text-white"
                            : "text-gray-900 border-2 hover:border-blue-600 hover:text-blue-600"
                    } group flex items-center rounded-md px-2 py-2 text-sm sm:text-sm`}
                >
                  {getTagIcon(tag)}
                  {t(`Tags.${tag}`)}
                </button>
            ))}
            <button
                onClick={toggleShowAllTags}
                className={`${
                    showAllTags
                        ? "bg-orange-600 text-white hover:bg-orange-700"
                        : "bg-blue-600 text-white hover:bg-blue-700"
                } group flex items-center rounded-md px-2 py-2 text-sm sm:text-sm`}
            >
              {showAllTags ? (
                  <>
                    <FaCircleChevronLeft className="mr-2" />
                    {t("ShowLessTags")}
                  </>
              ) : (
                  <>
                    <FaCircleChevronRight className="mr-2" />
                    {t("ShowAllTags")}
                  </>
              )}
            </button>
          </div>
        </div>
        {loading ? (
            <Spinner />
        ) : filteredRecords && filteredRecords.length > 0 ? (
            <DictionaryTable
                records={records}
                filteredRecords={filteredRecords}
                copyToClipboard={copyToClipboard}
            />
        ) : (
            <p>{t("NoRecordsFound")}</p>
        )}
      </div>
  );
}
