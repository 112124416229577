import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import LogoutButton from "./LogoutButton";

export default function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isFlagDropdownOpen, setIsFlagDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("de");
  const location = useLocation();
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const languageDropdownRef = useRef(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleLogin = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => handleLogin();
  }, [auth]);

  function pathMatchRoute(route) {
    return route === location.pathname;
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const toggleFlagDropdown = () => {
    setIsFlagDropdownOpen((prev) => !prev);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
    setCurrentLanguage(language); // Update the current language
    setIsFlagDropdownOpen(false); // Close dropdown after selection
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setIsFlagDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const languageOptions = {
    en: { name: "English", flag: "https://flagsapi.com/GB/flat/64.png" },
    de: { name: "Deutsch", flag: "https://flagsapi.com/DE/flat/64.png" },
    fr: { name: "Français", flag: "https://flagsapi.com/FR/flat/64.png" },
  };

  return (
    <header className="bg-gray-800 text-white p-6">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        {/* Logo and Language Switcher */}
        <div className="flex items-center">
          <Link
            to="/"
            className="text-2xl font-bold mr-3 bg-gradient-to-r from-red-600 to-red-600 bg-[length:33.33%_100%] bg-no-repeat bg-left"
            style={{
              background:
                "linear-gradient(90deg, white 33.33%, red 33.33%, red 66.66%, green 66.66%)",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            MADAGASSISCH.DE
          </Link>

          <div className="relative" ref={languageDropdownRef}>
            <button
              onClick={toggleFlagDropdown}
              className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-md flex items-center justify-center cursor-pointer"
            >
              <img
                src={languageOptions[currentLanguage].flag}
                alt={languageOptions[currentLanguage].name}
                className="h-5 w-5"
              />
              <span className="ml-2">
                {languageOptions[currentLanguage].name}
              </span>
            </button>
            {isFlagDropdownOpen && (
              <div className="absolute mt-2 w-40 bg-white text-gray-800 rounded-md shadow-lg z-20">
                {Object.entries(languageOptions).map(
                  ([key, { name, flag }]) => (
                    <button
                      key={key}
                      className="flex items-center justify-start w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => changeLanguage(key)}
                    >
                      <img src={flag} alt={name} className="h-5 w-5 mr-2" />
                      {name}
                    </button>
                  )
                )}
              </div>
            )}
          </div>
        </div>

        {/* Navigation Links */}
        <nav className="hidden lg:flex space-x-6">
          <Link
            to="/"
            className={`hover:underline ${
              pathMatchRoute("/") ? "underline" : ""
            }`}
          >
            {t("Home")}
          </Link>
          <Link
            to="/dictionary"
            className={`hover:underline ${
              pathMatchRoute("/dictionary") ? "underline" : ""
            }`}
          >
            {t("Dictionary")}
          </Link>
          <Link
            to="/about-us"
            className={`hover:underline ${
              pathMatchRoute("/about-us") ? "underline" : ""
            }`}
          >
            {t("AboutUs.Title")}
          </Link>
          <Link
            to="/contact"
            className={`hover:underline ${
              pathMatchRoute("/contact") ? "underline" : ""
            }`}
          >
            {t("Contact")}
          </Link>
          {user && (
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className={`hover:underline ${
                  isDropdownOpen ? "underline" : ""
                }`}
              >
                {t("Admin")}
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white text-gray-800 rounded-md shadow-lg z-10">
                  <Link
                    to="/admin-dashboard"
                    className="block px-4 py-2 hover:bg-gray-100"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    {t("Dashboard")}
                  </Link>
                  <Link
                    to="/admin-dictionary"
                    className="block px-4 py-2 hover:bg-gray-100"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    {t("Dictionary")}
                  </Link>
                  <Link
                    to="/admin-dictionary-review"
                    className="block px-4 py-2 hover:bg-gray-100"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    {t("Review")}
                  </Link>
                  <Link
                    to="/users"
                    className="block px-4 py-2 hover:bg-gray-100"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    {t("Users")}
                  </Link>
                </div>
              )}
            </div>
          )}
        </nav>

        {/* User Actions */}
        <div className="hidden lg:flex items-center space-x-4">
          {!user && (
            <Link
              to="/sign-in"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              {t("Sign in")}
            </Link>
          )}
          {user && <LogoutButton />} {/* Use LogoutButton component */}
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-gray-400 hover:text-white focus:outline-none"
          >
            {isMobileMenuOpen ? (
              <AiOutlineClose className="w-6 h-6 fill-current" />
            ) : (
              <GiHamburgerMenu className="w-6 h-6 fill-current" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-gray-800 text-white p-4 relative z-10">
          <nav className="space-y-4">
            <Link
              to="/"
              className={`block hover:underline ${
                pathMatchRoute("/") ? "underline" : ""
              }`}
              onClick={toggleMobileMenu}
            >
              {t("Home")}
            </Link>
            <Link
              to="/dictionary"
              className={`block hover:underline ${
                pathMatchRoute("/dictionary") ? "underline" : ""
              }`}
              onClick={toggleMobileMenu}
            >
              {t("Dictionary")}
            </Link>
            <Link
              to="/about-us"
              className={`block hover:underline ${
                pathMatchRoute("/about-us") ? "underline" : ""
              }`}
              onClick={toggleMobileMenu}
            >
              {t("AboutUs.Title")}
            </Link>
            <Link
              to="/contact"
              className={`block hover:underline ${
                pathMatchRoute("/contact") ? "underline" : ""
              }`}
              onClick={toggleMobileMenu}
            >
              {t("Contact")}
            </Link>
            {user && (
              <>
                <div className="relative">
                  <button
                    onClick={toggleDropdown}
                    className="block w-full text-left hover:underline"
                  >
                    {t("Dashboard")}
                  </button>
                  {isDropdownOpen && (
                    <div className="bg-gray-800 text-white p-4 space-y-4">
                      <Link
                        to="/admin-dashboard"
                        className="block hover:underline"
                        onClick={() => {
                          toggleMobileMenu();
                          setIsDropdownOpen(false);
                        }}
                      >
                        {t("Dashboard")}
                      </Link>
                      <Link
                        to="/admin-dictionary"
                        className="block hover:underline"
                        onClick={() => {
                          toggleMobileMenu();
                          setIsDropdownOpen(false);
                        }}
                      >
                        {t("Dictionary")}
                      </Link>
                      <Link
                        to="/admin-dictionary-review"
                        className="block hover:underline"
                        onClick={() => {
                          toggleMobileMenu();
                          setIsDropdownOpen(false);
                        }}
                      >
                        {t("Review")}
                      </Link>
                      <Link
                        to="/users"
                        className="block hover:underline"
                        onClick={() => {
                          toggleMobileMenu();
                          setIsDropdownOpen(false);
                        }}
                      >
                        {t("Users")}
                      </Link>
                    </div>
                  )}
                </div>
                <LogoutButton />
              </>
            )}
            {!user && (
              <Link
                to="/sign-in"
                className={`block hover:underline ${
                  pathMatchRoute("/sign-in") ? "underline" : ""
                }`}
                onClick={toggleMobileMenu}
              >
                {t("Sign in")}
              </Link>
            )}
          </nav>
        </div>
      )}
    </header>
  );
}
